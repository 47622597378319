<template>
  <div class="container">
    <div v-if="showLeft" class="left" :style="{ width: leftWidth + 'px', minWidth: MIN_LEFT_WIDTH + 'px' }">
      <slot name="left"></slot>
    </div>
    <div v-if="showResizer && showLeft && showRight" class="resizer" @mousedown="startDrag"></div>
    <div v-if="showRight" class="right">
      <slot name="right"></slot>
    </div>
    <slot name="others"></slot>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'DraggableLayout',
  props: {
    showLeft: {
      type: Boolean,
      default: true,
    },
    showRight: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const MIN_LEFT_WIDTH = 400;
    const leftWidth = ref(MIN_LEFT_WIDTH);
    const showResizer = props.showLeft && props.showRight;
    const startDrag = (e) => {
      e.preventDefault();
      const startX = e.clientX;
      const startWidth = leftWidth.value;
      const containerWidth = document.querySelector('.container').clientWidth;

      const onMouseMove = (e) => {
        const newWidth = startWidth + e.clientX - startX;
        if (newWidth >= MIN_LEFT_WIDTH && newWidth <= containerWidth * 0.5) {
          leftWidth.value = newWidth;
        }
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    return {
      leftWidth,
      showResizer,
      startDrag,
      MIN_LEFT_WIDTH,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  height: calc(100vh - 16px);
  justify-content: center;
  overflow: hidden;
  padding: 8px;
}

.left {
  max-width: 50%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 6px 3px #999;
}

.resizer {
  width: 10px;
  height: calc(100% - 16px);
  cursor: ew-resize;
  background-color: transparent; /* 默认透明 */
  transition: background-color 0.2s; /* 添加过渡效果 */
}

.resizer:hover {
  background-color: #ccc; /* 鼠标悬停时显示背景色 */
  border-radius: 5px;
  margin: 0 auto; /* 使圆柱体居中 */
}

.right {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 6px 3px #999;
}
</style>
