import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { createAuth0 } from '@auth0/auth0-vue';
import 'element-plus/theme-chalk/dark/css-vars.css';
import { i18n } from './i18n';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import './styles/global.scss';

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(i18n);
// 动态生成 redirect_uri
const redirectUri = `${window.location.protocol}//${window.location.host}/callback`;
// 读取环境变量
const auth0Domain = process.env.VUE_APP_AUTH0_DOMAIN as string;
const auth0ClientId = process.env.VUE_APP_AUTH0_CLIENTID as string;
const auth0Audience = process.env.VUE_APP_AUTH0_AUDIENCE as string;
app.use(
  createAuth0({
    domain: auth0Domain,
    clientId: auth0ClientId,
    authorizationParams: {
      audience: auth0Audience,
      redirect_uri: redirectUri,
    },
  })
);

// 将检测到的语言传递给应用
app.provide('detectedLanguage', i18n.global.locale);

app.mount('#app');
