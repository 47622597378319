<template>
  <div
    v-if="showCanvas"
    class="canvas-container"
    @wheel="handleWheel"
    @mousedown="startPan"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
    @click="showPopupAtClick"
  >
    <div ref="svgContainer" class="svg-content" v-html="svgContent"></div>
    <div class="title">{{ svgTitle }}</div>
    <div class="zoomFit" :title="t('operation.fitScreen')" @click="resetDiagram">
      <svg
        class="icon"
        width="32px"
        height="32px"
        viewBox="0 0 1280 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#777"
          d="M256 256h768v512H256zM128 320H0V64a64 64 0 0 1 64-64h256v128H128zM1280 320h-128V128h-192V0h256a64 64 0 0 1 64 64zM320 1024H64a64 64 0 0 1-64-64v-256h128v192h192zM1216 1024h-256v-128h192v-192h128v256a64 64 0 0 1-64 64z"
        />
      </svg>
    </div>
    <div v-if="showPopup" class="popup" :style="popupPosition">
      <div class="popup-content">
        <p>这里是浮动层的内容</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, computed, nextTick } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CanvasContainer',
  props: {
    showCanvas: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const svgContainer = ref<HTMLElement | null>(null);
    const scale = ref(1);
    const pan = ref({ x: 0, y: 0 });
    const isPanning = ref(false);
    const lastPanPosition = ref({ x: 0, y: 0 });
    const { t } = useI18n();

    const svgContent = computed(() => store.getters.getSvgContent);
    const svgTitle = computed(() => store.getters.getSvgTitle);

    const showPopup = ref(false);
    const popupPosition = ref({ top: '0px', left: '0px' });
    const TARGET_POS = {
      TARGET_X: 10,
      TARGET_Y: 10,
    };

    // 触摸相关状态
    const touchState = ref({
      startDistance: 0,
      initialScale: 1,
      lastTouchX: 0,
      lastTouchY: 0,
    });

    // 计算图形的实际边界，考虑缩放和平移的影响
    const getContentBounds = (newScale: number) => {
      if (!svgContainer.value || !svgContainer.value.parentElement) {
        return null;
      }

      const containerRect = svgContainer.value.parentElement.getBoundingClientRect();
      const svgRect = svgContainer.value.getBoundingClientRect();

      // 计算缩放后的尺寸
      const scaledWidth = svgRect.width * (newScale / scale.value);
      const scaledHeight = svgRect.height * (newScale / scale.value);

      // 考虑平移影响的边界
      return {
        left: pan.value.x * newScale,
        top: pan.value.y * newScale,
        right: pan.value.x * newScale + scaledWidth,
        bottom: pan.value.y * newScale + scaledHeight,
        width: scaledWidth,
        height: scaledHeight,
        containerWidth: containerRect.width,
        containerHeight: containerRect.height,
      };
    };

    // 检查内容是否完全可见，考虑平移影响
    const isContentFullyVisible = (newScale: number): boolean => {
      const bounds = getContentBounds(newScale);
      if (!bounds) return true;
      // 内容完全可见的条件：内容大小小于容器，且位置适当
      return (
        bounds.width + TARGET_POS.TARGET_X <= bounds.containerWidth &&
        bounds.height + TARGET_POS.TARGET_Y <= bounds.containerHeight
      );
    };

    // 调整平移值，逐渐接近目标位置
    const adjustPanTowardsTarget = () => {
      const bounds = getContentBounds(scale.value);
      if (!bounds) return false;

      let needsAdjustment = false;

      // 只有当内容小于容器时才进行平移调整
      if (bounds.width < bounds.containerWidth && bounds.height < bounds.containerHeight) {
        const currentX = pan.value.x * scale.value;
        const currentY = pan.value.y * scale.value;

        // 计算与目标位置的差异
        const diffX = TARGET_POS.TARGET_X - currentX;
        const diffY = TARGET_POS.TARGET_Y - currentY;

        // 如果差异大于50像素，减少50%的差异；否则直接设置为目标位置
        if (Math.abs(diffX) > 50 || Math.abs(diffY) > 50) {
          pan.value.x = (currentX + diffX * 0.5) / scale.value;
          pan.value.y = (currentY + diffY * 0.5) / scale.value;
          needsAdjustment = true;
        } else if (Math.abs(diffX) > 1 || Math.abs(diffY) > 1) {
          pan.value.x = TARGET_POS.TARGET_X / scale.value;
          pan.value.y = TARGET_POS.TARGET_Y / scale.value;
          needsAdjustment = true;
        }
      }

      return needsAdjustment;
    };

    // 防抖提示
    let debounceTimer: any | null = null;
    const showScaleLimitMessage = (message: string) => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      debounceTimer = setTimeout(() => {
        ElMessage.info(message);
      }, 100);
    };

    // 更新变换
    const updateTransform = () => {
      if (svgContainer.value) {
        svgContainer.value.style.transform = `scale(${scale.value}) translate(${pan.value.x}px, ${pan.value.y}px)`;
      }
    };

    // 限制缩放范围
    const constrainScale = (newScale: number) => {
      if (newScale > 4) {
        scale.value = 4;
        showScaleLimitMessage(t('diagram.maxLimit'));
        return false;
      }

      // 如果新的缩放比例会导致内容小于容器
      if (newScale < scale.value && isContentFullyVisible(scale.value)) {
        // 不改变缩放比例，而是调整平移量
        if (adjustPanTowardsTarget()) {
          updateTransform();
        } else {
          showScaleLimitMessage(t('diagram.minLimit'));
        }
        return false;
      }

      scale.value = newScale;
      return true;
    };

    let debounceScale: any | null = null;
    // 鼠标滚轮处理
    const handleWheel = (e: WheelEvent) => {
      if (debounceScale) {
        clearTimeout(debounceScale);
      }
      debounceScale = setTimeout(() => {
        e.preventDefault();
        const zoomFactor = 1.1;
        const delta = e.deltaY > 0 ? 1 / zoomFactor : zoomFactor;
        const newScale = scale.value * delta;
        constrainScale(newScale);
        updateTransform();
      }, 25);
    };

    // 开始鼠标拖动
    const startPan = (e: MouseEvent) => {
      if (e.button !== 0) return; // 只响应左键
      e.preventDefault();
      isPanning.value = true;
      lastPanPosition.value = { x: e.clientX, y: e.clientY };

      const onMouseMove = (e: MouseEvent) => {
        if (isPanning.value) {
          const dx = (e.clientX - lastPanPosition.value.x) / scale.value;
          const dy = (e.clientY - lastPanPosition.value.y) / scale.value;
          pan.value.x += dx;
          pan.value.y += dy;
          lastPanPosition.value = { x: e.clientX, y: e.clientY };
          updateTransform();
        }
      };

      const onMouseUp = () => {
        isPanning.value = false;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    // 计算两个触摸点之间的距离
    const getTouchDistance = (touch1: Touch, touch2: Touch): number => {
      const dx = touch1.clientX - touch2.clientX;
      const dy = touch1.clientY - touch2.clientY;
      return Math.sqrt(dx * dx + dy * dy);
    };

    // 触摸开始
    const handleTouchStart = (e: TouchEvent) => {
      e.preventDefault();
      const touches = e.touches;

      if (touches.length === 1) {
        // 单指触摸 - 准备平移
        touchState.value.lastTouchX = touches[0].clientX;
        touchState.value.lastTouchY = touches[0].clientY;
      } else if (touches.length === 2) {
        // 双指触摸 - 准备缩放
        touchState.value.startDistance = getTouchDistance(touches[0], touches[1]);
        touchState.value.initialScale = scale.value;
      }
    };

    // 触摸移动
    const handleTouchMove = (e: TouchEvent) => {
      e.preventDefault();
      const touches = e.touches;

      if (touches.length === 1) {
        // 单指移动 - 处理平移
        const dx = (touches[0].clientX - touchState.value.lastTouchX) / scale.value;
        const dy = (touches[0].clientY - touchState.value.lastTouchY) / scale.value;
        pan.value.x += dx;
        pan.value.y += dy;
        touchState.value.lastTouchX = touches[0].clientX;
        touchState.value.lastTouchY = touches[0].clientY;
      } else if (touches.length === 2) {
        // 双指移动 - 处理缩放
        const currentDistance = getTouchDistance(touches[0], touches[1]);
        const scaleFactor = currentDistance / touchState.value.startDistance;
        const newScale = touchState.value.initialScale * scaleFactor;
        constrainScale(newScale);
      }
      updateTransform();
    };

    // 触摸结束
    const handleTouchEnd = (e: TouchEvent) => {
      if (e.touches.length === 0) {
        // 重置触摸状态
        touchState.value = {
          startDistance: 0,
          initialScale: scale.value,
          lastTouchX: 0,
          lastTouchY: 0,
        };
      }
    };

    // 点击显示弹窗
    const showPopupAtClick = (e: MouseEvent) => {
      const canvasContainer = svgContainer.value?.parentElement;
      if (canvasContainer) {
        const rect = canvasContainer.getBoundingClientRect();
        const offsetX = rect.left;
        const offsetY = rect.top;

        // 取消注释以启用弹窗功能
        /*
        showPopup.value = true;
        popupPosition.value = {
          top: `${e.clientY - offsetY}px`,
          left: `${e.clientX - offsetX}px`,
        };
        */
      }
    };

    // 监听画布和SVG内容变化
    watch(
      () => [props.showCanvas, svgContent.value],
      () => {
        nextTick(() => {
          if (props.showCanvas && svgContainer.value) {
            updateTransform();
          }
        });
      }
    );

    const resetDiagram = () => {
      initDiagram();
      ElMessage.info(t('diagram.reset'));
    };
    const initDiagram = () => {
      if (svgContainer.value) {
        const canvasContainer = svgContainer.value.parentElement;
        if (canvasContainer) {
          const canvasWidth = canvasContainer.clientWidth;
          const canvasHeight = canvasContainer.clientHeight;
          const svgWidth = svgContainer.value.clientWidth;
          const svgHeight = svgContainer.value.clientHeight;

          // 计算初始缩放比例，使内容适应容器
          const scaleX = (canvasWidth / svgWidth) * 0.9;
          const scaleY = (canvasHeight / svgHeight) * 0.9;
          const initialScale = Math.min(scaleX, scaleY, 4);

          scale.value = initialScale;

          // 居中显示
          pan.value.x = (canvasWidth - svgWidth * initialScale) / (2 * initialScale);
          pan.value.y = (canvasHeight - svgHeight * initialScale) / (2 * initialScale);
          TARGET_POS.TARGET_X = pan.value.x * initialScale;
          TARGET_POS.TARGET_Y = pan.value.y * initialScale;
          updateTransform();
        }
      }
    };

    // 组件挂载时初始化
    onMounted(() => {
      initDiagram();
    });

    return {
      svgContainer,
      handleWheel,
      startPan,
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
      showPopupAtClick,
      svgContent,
      svgTitle,
      showPopup,
      popupPosition,
      t,
      resetDiagram,
    };
  },
});
</script>

<style scoped>
.canvas-container {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  touch-action: none;
}

.svg-content {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transition: transform 0.05s linear;
  will-change: transform;
}

.title {
  position: absolute;
  bottom: 6px;
  left: 6px;
  font-size: 20px;
  border-radius: 5px;
  background-color: #bbb;
  padding: 0px 7px;
  color: #333;
}

.popup {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  font-size: 14px;
  color: #333;
}

.zoomFit {
  position: absolute;
  right: 6px;
  top: 6px;
}
</style>
