<template>
  <svg
    class="icon"
    width="200px"
    height="200.00px"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#13227a"
      d="M179.285333 721.578667a390.570667 390.570667 0 0 0 326.4 174.506666c214.912 0 389.162667-171.733333 389.162667-383.573333 0-187.989333-137.301333-344.704-318.848-377.344 10.666667 39.253333 16.170667 80.085333 16.170667 121.6 0 237.994667-179.882667 434.517333-412.885334 464.810667z m-69.333333-81.237334h6.570667c214.912 0 389.12-171.690667 389.12-383.573333 0-53.504-11.093333-105.386667-32.298667-153.344L446.421333 42.666667l67.242667 1.109333C772.992 48 981.333333 256.554667 981.333333 512.512 981.333333 771.456 768.384 981.333333 505.685333 981.333333c-190.890667 0-361.002667-111.872-436.053333-281.344L42.666667 639.232l67.285333 1.109333z"
    />
  </svg>
</template>
