<template>
  <svg
    class="icon"
    width="200px"
    height="199.61px"
    viewBox="0 0 1026 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#13227a"
      d="M514.352755 875.249152a37.804429 37.804429 0 0 1 37.165841 30.971534l0.638588 6.769036v70.947163a37.804429 37.804429 0 0 1-75.225704 6.832895l-0.638589-6.769037v-70.947162a37.804429 37.804429 0 0 1 37.804429-37.804429z m305.1175-111.561379l5.938871 4.853271 50.193042 50.193042a37.804429 37.804429 0 0 1-47.510971 58.239255l-5.938871-4.853271-50.193042-50.193042a37.804429 37.804429 0 0 1 47.510971-58.239255z m-562.787887 4.853271a37.804429 37.804429 0 0 1 4.853271 47.510971l-4.853271 5.938871-50.193042 50.193042a37.804429 37.804429 0 0 1-58.239255-47.510971l4.853271-5.938871 50.193042-50.193042a37.804429 37.804429 0 0 1 53.449843 0z m257.670387-527.984824a270.314436 270.314436 0 1 1-270.378295 270.378295 270.314436 270.314436 0 0 1 270.378295-270.378295z m473.066228 232.573867a37.804429 37.804429 0 0 1 6.769037 74.970269l-6.769037 0.638588h-70.947162a37.804429 37.804429 0 0 1-6.769036-75.225704l6.769036-0.638589h70.947162z m-875.249152 0a37.804429 37.804429 0 0 1 6.769036 74.970269l-6.769036 0.638588H41.222669a37.804429 37.804429 0 0 1-6.769037-75.225704l6.769037-0.638589h71.011021z m763.432337-323.444985a37.804429 37.804429 0 0 1 4.853272 47.510971l-4.853272 5.938872-50.193042 50.193042a37.804429 37.804429 0 0 1-58.239254-47.510971l4.853271-5.938872 50.193042-50.193042a37.804429 37.804429 0 0 1 53.385983 0z m-674.987855-4.853271l5.938872 4.853271 50.193042 50.193042a37.804429 37.804429 0 0 1-47.510971 58.239255l-5.938872-4.853271-50.193042-50.193042a37.804429 37.804429 0 0 1 47.510971-58.239255zM514.352755 0.063859a37.804429 37.804429 0 0 1 37.165841 30.971533l0.638588 6.769037v71.011021a37.804429 37.804429 0 0 1-75.225704 6.769036l-0.638589-6.769036V37.804429a37.804429 37.804429 0 0 1 38.059864-37.74057z"
    />
  </svg>
</template>
