// src/store/plugins/title.ts
import { Store } from 'vuex';
import { i18n } from '@/i18n';
import router from '@/router';

export default function titlePlugin(store: Store<any>) {
  store.subscribe((mutation, state) => {
    if (mutation.type === 'SET_CURRENT_LANGUAGE') {
      const currentRoute = router?.currentRoute.value;
      const meta = currentRoute?.meta as { title?: string };
      if (meta && meta.title) {
        i18n.global.locale.value = state.currentLanguage;
        document.title = i18n.global.t(meta.title);
      }
    }
  });
}
