import { createStore } from 'vuex';
import router from '@/router';
import { createAuth0 } from '@auth0/auth0-vue';
import createPersistedState from 'vuex-persistedstate';
import titlePlugin from '@/plugins/title';

export default createStore({
  state: {
    viewGuide: true,
    accessToken: null,
    currentPath: '/',
    auth0Client: null,
    openXMLContent: '',
    svgContent: '',
    svgTitle: '',
    isMerged: true,
    isLanguageSelecting: false,
    currentLanguage: navigator.language || 'en-US', // 使用浏览器默认语言
  },
  mutations: {
    SET_CURRENT_PATH(state, path) {
      state.currentPath = path;
    },
    SET_CURRENT_LANGUAGE(state, language) {
      state.currentLanguage = language;
    },
    SET_AUTH0_CLIENT(state, client) {
      state.auth0Client = client;
    },
    setOpenXMLContent(state, content) {
      state.openXMLContent = content;
    },
    setSvgContent(state, content) {
      state.svgContent = content;
    },
    setSvgTitle(state, content) {
      state.svgTitle = content;
    },
    setIsMerged(state, isMerged) {
      state.isMerged = isMerged;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setViewGuide(state, viewGuide) {
      state.viewGuide = viewGuide;
    },
    setLanguageSelecting(state, isSelecting) {
      state.isLanguageSelecting = isSelecting;
    },
  },
  actions: {
    navigateTo({ commit }, path) {
      commit('SET_CURRENT_PATH', path);
      router.push(path);
    },
    async initializeAuth0({ commit }) {
      // 动态生成 redirect_uri
      const redirectUri = `${window.location.protocol}//${window.location.host}/callback`;

      const client = await createAuth0({
        domain: 'dev-osip47symegcuuho.us.auth0.com',
        clientId: 'fTYw1YAHGkZK3n1x2xtqn0jK8T2aGbTn',
        authorizationParams: {
          redirect_uri: redirectUri,
        },
      });
      commit('SET_AUTH0_CLIENT', client);
    },
    updateOpenXMLContent({ commit }, content) {
      commit('setOpenXMLContent', content);
    },
    updateSvgContent({ commit }, content) {
      commit('setSvgContent', content);
    },
    updateSvgTitle({ commit }, content) {
      commit('setSvgTitle', content);
    },
    updateIsMerged({ commit }, isMerged) {
      commit('setIsMerged', isMerged);
    },
    setCurrentLanguage({ commit }, language) {
      commit('SET_CURRENT_LANGUAGE', language);
    },
    setAccessToken({ commit }, token) {
      commit('setAccessToken', token);
    },
    setViewGuide({ commit }, token) {
      commit('setViewGuide', token);
    },
    setLanguageSelecting({ commit }, isSelecting) {
      commit('setLanguageSelecting', isSelecting);
    },
  },
  getters: {
    auth0Client: (state) => state.auth0Client,
    getOpenXMLContent: (state) => state.openXMLContent,
    getSvgContent: (state) => state.svgContent,
    getSvgTitle: (state) => state.svgTitle,
    getIsMerged: (state) => state.isMerged,
    currentLanguage: (state) => state.currentLanguage,
    accessToken: (state) => state.accessToken,
    viewGuide: (state) => state.viewGuide,
    languageSelecting: (state) => state.isLanguageSelecting,
  },
  modules: {},
  plugins: [createPersistedState(), titlePlugin],
});
