export default {
  menu: {
    home: '首頁',
    demo: '演示',
    document: '文檔',
    classDiagram: '類圖工具',
    bop: '面向業務程式設計',
    logout: '登出',
    login: '登錄',
    dark: '暗',
    light: '亮',
    profile: '我的',
  },
  diagram: {
    maxLimit: '已達到最大縮放限制',
    minLimit: '圖形已完全顯示在視圖中',
    reset: '圖形已適配螢幕',
  },
  guide: {
    input: '主輸入區域。最多128字符限制。交互信息在上方顯示',
    limit: '可用LLM請求次數',
    language: '亮/暗 主題和界面語言選擇',
    control: '圖形/文檔切換，導出和圖形分離/合併操作',
    title: '圖形標題信息',
    main: '主圖形/文檔區域',
    action:
      '本頁面是用來進行類別圖編輯的工具。支援創建類別、屬性以及類別之間的關係。您可以進一步查看演示介紹文檔以獲取更多信息，或者先開始體驗然後通過文檔菜單了解更多信息。',
    button1: '查看文檔',
    button2: '開始工作',
    help: '顯示本信息屏幕',
    fitScreen: '調整圖形適應畫面',
  },
  help: '幫助',
  attention: '請注意',
  known: '我知道了',
  powered: '由 deepseek 驅動',
  emailAlert1: '請完成您的電子郵件驗證後嘗試再登錄',
  emailAlert2: '請檢查垃圾郵件分類以確認郵件是否到達',
  today: '今天',
  tomorrow: '明天',
  requestQuota: '到{day} {time} 以前還有 {remain}/{total} 請求次數',
  loadingError: '資料載入錯誤，請重新刷新頁面或稍後再試',
  badCredentials: '使用者資訊錯誤，將回傳首頁',
  actionSuccess: '請求執行成功',
  actionsSuccess: '請求執行成功',
  actionInvalid: '請求無效',
  outOfQuota: '您的LLM請求餘額不足',
  commonError: '處理請求執行結果信息失敗',
  template: '處理`{instructionName}`請求時`{validationTypeName}`驗證失敗。請檢查`{paramType}`參數的值:{params}',
  noMoreRollback: '沒有更多記錄可以回滾',
  noMoreTokens: '您的token餘額不足，請聯繫管理員處理',
  restrictionInfo: '了解用量详情',
  register: '登入/註冊',
  registerAlert: '請先登入註冊後體驗',
  operation: {
    document: '文檔',
    export: '導出',
    split: '分離',
    merge: '合併',
    diagram: '圖形',
    fitScreen: '適應螢幕',
  },
  chat: {
    you: '你',
    pleaseInput: '請輸入交互信息',
    info: '消息',
    error: '錯誤',
    warning: '警告',
    moreHistory: '更多歷史消息已經刪除',
    maxLengthReached: '已經達到最大長度',
    emptyInfoNotAllow: '請不要提交空信息',
    copy: '複製',
    retry: '重新發送',
    submit: '提交',
    sending: '您的請求正在被處理',
    cancel: '刪除內容',
    unrecognizedMessage: '識別失敗',
  },
  title: {
    bop: 'BOP',
    homePage: 'BOP - 重新定義軟體開發',
    aboutPage: '關於',
    userProfile: '用戶資料',
    classDiagram: '類圖工具',
    classDiagramDoc: '類圖文檔',
    classDiagramResults: '類圖結果',
  },
  instructions: {
    '10100': '刪除關係',
    '10150': '刪除方法',
    '10200': '刪除類屬性',
    '10300': '刪除類',
    '10350': '批次刪除類別',
    '10400': '添加類',
    '10450': '批次添加類',
    '10500': '添加屬性名',
    '10600': '添加或修改雙向關係',
    '10700': '添加或修改單向關係',
    '10800': '添加或修改謂詞',
    '10900': '添加或修改關係類型',
    '10910': '添加關係類型',
    '10915': '添加方法',
    '10920': '變更關係類型',
    '10930': '變更關係謂詞',
    '10940': '變更關係數量',
    '10950': '反轉關係方向',
    '11000': '修改屬性約束',
    '11150': '修改類別屬性資料型別',
    '11100': '修改類屬性名',
    '11200': '修改類名',
    '11250': '修改方法名',
    '11300': '修改圖形名稱',
    '11400': '重置類圖',
    '11500': '清空類圖',
    '11600': '撤銷圖形操作',
    '20100': '分離視圖',
    '20200': '合併視圖',
    '20300': '顯示文檔',
    '20400': '顯示圖形',
    '20500': '導出文檔',
    '20600': '導出圖形',
  },
  validateTypes: {
    '100': { validationTypeName: '類名稱應當存在', paramType: '類名稱' },
    '200': { validationTypeName: '類名稱不應當存在', paramType: '類名稱' },
    '300': { validationTypeName: '類名稱不能為空', paramType: '類名稱' },
    '400': { validationTypeName: '類名稱應當合法', paramType: '類名稱' },
    '500': { validationTypeName: '屬性名稱應當存在', paramType: '屬性名稱' },
    '600': { validationTypeName: '屬性名稱不應當存在', paramType: '屬性名稱' },
    '700': { validationTypeName: '屬性名稱不能為空', paramType: '屬性名稱' },
    '800': { validationTypeName: '屬性名稱應當合法', paramType: '屬性名稱' },
    '900': { validationTypeName: '約束應當合法', paramType: '約束' },
    '1000': { validationTypeName: '關係應當存在', paramType: '關係' },
    '1100': { validationTypeName: '關係不應當存在', paramType: '關係' },
    '1200': { validationTypeName: '關係類型應當是關聯類型', paramType: '關係類型' },
    '1300': { validationTypeName: '關係類型應當合法', paramType: '關係類型' },
    '1400': { validationTypeName: '關係類型不應當是繼承類型', paramType: '關係類型' },
    '1500': { validationTypeName: '關係數量應當合法', paramType: '數量' },
    '1600': { validationTypeName: '謂詞應當合法', paramType: '謂詞' },
    '1700': { validationTypeName: '謂詞不能為空', paramType: '謂詞' },
    '1800': { validationTypeName: '類圖名稱不能為空', paramType: '類圖名稱' },
    '1900': { validationTypeName: '重設類別圖參數錯誤', paramType: '類別圖名稱' },
    '2000': { validationTypeName: '方法名稱不能為空', paramType: '方法名稱' },
    '2100': { validationTypeName: '方法名稱無效', paramType: '方法名稱' },
    '2200': { validationTypeName: '方法已經存在', paramType: '方法名稱' },
    '2300': { validationTypeName: '方法不存在', paramType: '方法名稱' },
    '2400': { validationTypeName: '批次操作', paramType: '類別圖名稱' },
    '2500': { validationTypeName: '資料型別無效', paramType: '資料型別' },
    '2600': { validationTypeName: '參數無效', paramType: '參數' },
  },
  serverError: {
    common: '服務異常，請重試或聯繫網站管理員。',
    unknownerror: '服務異常，請重試或聯繫網站管理員',
    llmfailed: 'LLM 返回數據錯誤',
    rendersvgfailed: 'plantUML渲染錯誤',
    lengthlimit: '對話數據超長錯誤',
    outofquota: '請求次數耗盡',
    outoftoken: 'token耗盡',
    exportdocfailed: '文檔導出錯誤',
    documentdatafailed: '文檔導出錯誤',
  },
  home: [
    {
      title: '程式設計的本質是創造',
      contents: [
        '程式設計是一種藝術，但是編碼不是',
        '程式設計不只是編碼，而是一種創造性的思維過程',
        '我們需要重新思考：如何讓創造性思維更自然、更高效?',
      ],
    },
    {
      title: '突破傳統人機交互的限制',
      contents: [
        '您只需負責思考，讓工具負責實現',
        '摒棄繁瑣的介面操作，轉向自然語言和自然交互',
        '用語音、文字和簡單操作隨心所欲地表達您的设计意圖',
      ],
    },
    {
      title: '自動化文檔處理革命',
      contents: [
        '您不喜歡寫文檔，我也是',
        '我們演示從自由表達直接生成標準化設計文檔',
        '我們會讓設計即是文檔，文檔即是設計，文檔既是程式碼',
      ],
    },
    {
      title: '工業化的軟體開發新範式',
      contents: [
        '可重複、可驗證是工業化的特徵',
        '我們提供標準化的業務邏輯處理流程',
        '從您的大腦開始實現軟體開發過程全流程的工業化',
      ],
    },
    {
      title: 'LLM驅動的智能協作',
      contents: [
        'AI不僅是工具，更是您的開發夥伴',
        '讓每個決策都建立在智能分析的基礎上',
        '實時理解您的意圖，並提供專業建議和廣泛的選擇',
      ],
    },
    {
      title: '體驗未來，就在當下',
      contents: [
        '率先體驗下一代軟體開發模式',
        '親身感受AI輔助開發的革命性變化',
        '面向業務程式設計，以業務為核心的開發範式已經出現',
      ],
    },
  ],
  profile: {
    title: '個人信息',
    basePart: {
      title: '基礎信息',
      userName: '用戶名',
      email: '電子郵箱',
    },
    tokenPart: {
      title: 'Token信息',
      content: '作為一個demo，我們只提供有限的體驗。如果您需要更多額度，請直接聯繫作者有關詳情',
      remain: '當前剩餘',
      added: '累計添加',
      consumed: '累計消耗',
      request: '累計請求',
      avg: '平均消耗',
      addHistory: 'Token獲取歷史',
      consumeByM: '按月消耗',
      date: '日期',
      count: '數量',
      tokens: '獲得量',
      tokenConsumed: '消費量',
      last30: '最近30次請求',
      noData: '暫無數據',
    },
  },
  helpMessage: {
    line1: '歡迎回饋新一代人機互動體驗',
    line2: '讓AI完成你的願望',
    line3: '釋放您的雙手，專注您的設計',
    line4: '請參考文件了解語音輸入模式',
    line5: '請參考文件了系統基礎能力',
    line6: '請參考文件獲得類別圖編輯基礎',
  },
};
