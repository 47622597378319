<template>
  <div class="chat-container" ref="chatContainer">
    <div v-if="currentMessages.length == 15" :class="['more-info', { 'dark-mode': isDarkMode }]">
      &lt;&lt;{{ $t('chat.moreHistory') }}&gt;&gt;
    </div>
    <div
      v-for="(message, index) in currentMessages"
      :key="index"
      :class="['chat-bubble', message.type, { 'dark-mode': isDarkMode }]"
      @click="showButtons(index)"
    >
      <!-- 添加 SVG 图标和 'robot' 文字 -->
      <div class="bubble-header">
        <svg
          class="svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          :fill="fillColor"
          width="24"
          height="24"
        >
          <circle cx="12" cy="12" r="8" :fill="fillBGColor" />
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
          />
        </svg>
        <span :class="['msg-text', 'eg-' + message.type]">{{ $t(`chat.${message.type}`) }}</span>
      </div>
      <div class="bubble-content">{{ message.text }}</div>
      <div
        v-if="message.showButtons && message.type == 'you'"
        :class="['button-container', { show: message.showButtons }]"
      >
        <button @click="handleButtonClick('copy', message.text)">{{ $t('chat.copy') }}</button>
        <button @click="handleButtonClick('retry', message.text)">{{ $t('chat.retry') }}</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, onMounted, onUpdated, nextTick, watch } from 'vue';
import { useDark } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

interface Message {
  type: 'system' | 'you' | 'warning' | 'error' | 'info';
  text: string;
  showButtons?: boolean;
}

export default defineComponent({
  name: 'ChatBubble',
  props: {
    messages: {
      type: Array as PropType<Message[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const chatContainer = ref<HTMLElement | null>(null);
    const isDarkMode = useDark();
    const currentMessages = ref<Message[]>([]);
    const { t } = useI18n();
    const fillColor = ref(isDarkMode.value ? '#ccc' : '#000');
    const fillBGColor = ref(isDarkMode.value ? '#000' : '#ccc');
    let sendingInterval: any = null;
    // 自定义函数，当 messages 变化时执行
    const customFunction = (newMessage: Message[]) => {
      if (
        currentMessages.value &&
        currentMessages.value.length > 0 &&
        currentMessages.value[currentMessages.value.length - 1].text.startsWith(t('chat.sending'))
      ) {
        currentMessages.value = [
          ...currentMessages.value.slice(0, -1),
          ...newMessage.map((msg) => ({ ...msg, showButtons: false })),
        ];
      } else {
        currentMessages.value = [
          ...currentMessages.value,
          ...newMessage.map((msg) => ({ ...msg, showButtons: false })),
        ];
      }
      if (currentMessages.value.length > 30) {
        currentMessages.value = [...currentMessages.value].slice(-30);
      }
      if (sendingInterval) {
        clearInterval(sendingInterval);
      }
      if (currentMessages.value[currentMessages.value.length - 1].text.startsWith(t('chat.sending'))) {
        sendingInterval = setInterval(() => {
          currentMessages.value[currentMessages.value.length - 1].text += '.';
        }, 1000);
      }
    };

    // 在组件挂载和更新时，自动滚动到最底部
    onMounted(() => {
      scrollToBottom();
    });

    onUpdated(() => {
      nextTick(() => {
        scrollToBottom();
      });
    });

    const scrollToBottom = () => {
      if (chatContainer.value) {
        chatContainer.value.scrollTop = chatContainer.value.scrollHeight - chatContainer.value.clientHeight;
      }
    };

    watch(
      () => isDarkMode.value,
      (newValue) => {
        if (newValue == true) {
          fillColor.value = '#ccc';
          fillBGColor.value = '#000';
        } else {
          fillColor.value = '#000';
          fillBGColor.value = '#ccc';
        }
      }
    );
    // 监听 messages 的变化
    watch(
      () => props.messages,
      (newMessages) => {
        if (sendingInterval) {
          clearInterval(sendingInterval);
        }
        customFunction(newMessages);
        scrollToBottom();
      }
    );
    // 删除学校、学生、教师、班级、课程、年级、学科、教学楼
    // 创建学校、学生、教师、班级、课程、年级、学科、教学楼
    const showButtons = (index: number) => {
      for (let i = 0; i < currentMessages.value.length; i++) {
        if (i != index) {
          currentMessages.value[i].showButtons = false;
        }
      }
      currentMessages.value[index].showButtons = !currentMessages.value[index].showButtons;
      const obj = JSON.parse(JSON.stringify(currentMessages.value));
      currentMessages.value = obj;
    };

    const handleButtonClick = (buttonType: 'copy' | 'retry', text: string) => {
      emit('messageAction', buttonType, text);
    };

    return {
      chatContainer,
      isDarkMode,
      currentMessages,
      showButtons,
      handleButtonClick,
      fillColor,
      fillBGColor,
    };
  },
});
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  max-width: 100%;
  max-height: 100%; /* 设置最大高度 */
  overflow-y: scroll;
  scrollbar-width: none;
  padding-top: 10px;
}

.chat-bubble {
  padding: 5px;
  margin: 3px 0;
  border-radius: 5px;
  word-wrap: break-word;
  width: calc(100% - 60px);
  display: flex;
  flex-direction: row;
  position: relative;
}

.chat-bubble:last-child {
  margin-bottom: 7px;
}

.you.chat-bubble {
  flex-direction: row-reverse;
}

.bubble-header {
  display: flex;
  align-items: center;
}

.you .bubble-header {
  flex-direction: row-reverse;
}

.msg-text {
  font-size: 12px;
  color: #fff;
  padding: 1px 5px 2px;
  border-radius: 10px;
  margin: 0 2px 0 6px;
  word-break: keep-all;
}
.more-info {
  font-size: 12px;
}
.more-info.dark-mode {
  color: #666;
}
.msg-text.eg-info {
  background-color: #007bff;
  color: #eee;
}

.msg-text.eg-error {
  background-color: darkred;
  color: #eee;
}

.msg-text.eg-warning {
  background-color: darkorange;
  color: #eee;
}

.bubble-content {
  text-align: left;
  margin: 0 0 0 3px;
  align-content: center;
  word-wrap: break-word;
  word-break: break-word;
  font-size: 14px;
}

.you .bubble-content {
  flex-grow: 1;
  text-align: start;
}

.system {
  background-color: transparent;
  color: #000;
  border: 1px solid #007bff;
}

.you {
  background-color: #eee;
  color: #000;
}

.info {
  background-color: #eee;
  color: #000;
  border: 2px solid #007bff;
}
.info .svg,
.warning .svg,
.error .svg {
  position: absolute;
  top: -13px;
  left: -6px;
}
.you .svg {
  position: absolute;
  right: -6px;
  top: -13px;
}
.dark-mode.info {
  border: 2px solid #007bff;
  background-color: #222;
  color: #bbb;
}
.warning {
  background-color: #eee;
  color: #000;
  border: 2px solid darkorange;
}
.dark-mode.warning {
  border: 1px solid darkorange;
  background-color: #222;
  color: #bbb;
}

.error {
  background-color: #eee;
  color: #000;
  border: 2px solid darkred;
}
.dark-mode.error {
  border: 2px solid darkred;
  background-color: #222;
  color: #bbb;
}

.you {
  background-color: #007bff;
  color: #fff;
  align-self: end;
}

.dark-mode.eg-warning {
  color: #888;
}
.dark-mode.eg-error {
  color: #888;
}
.dark-mode.eg-info {
  color: #888;
}

.button-container {
  position: absolute;
  top: 50%;
  right: -46px;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-right: 32px;
}

.show {
  opacity: 1;
}
.button-container button {
  border: none;
  background-color: lightskyblue;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  height: 20px;
}

.button-container button:hover {
  background-color: darkblue;
}
</style>
