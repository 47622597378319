<template>
  <div class="toolbar" :class="{ expanded: isHover }" @mouseenter="isHover = true" @mouseleave="isHover = false">
    <div class="toolbar-content">
      <div
        class="icon"
        @click="toggleContent"
        v-if="isMerged"
        :title="showCanvas ? $t('operation.document') : $t('operation.diagram')"
      >
        <svg
          v-if="!showCanvas"
          width="24"
          height="24"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#ffffff"
            d="M988.16 621.568H867.328v-98.304c0-17.408-13.312-30.72-30.72-30.72H545.792v-90.112h120.832c17.408 0 30.72-13.312 30.72-30.72V68.608c0-17.408-13.312-30.72-30.72-30.72H363.52c-17.408 0-30.72 13.312-30.72 30.72v303.104c0 17.408 13.312 30.72 30.72 30.72h120.832v90.112h-296.96c-17.408 0-30.72 13.312-30.72 30.72v98.304H35.84c-17.408 0-30.72 13.312-30.72 30.72v303.104c0 17.408 13.312 30.72 30.72 30.72h303.104c17.408 0 30.72-13.312 30.72-30.72V652.288c0-17.408-13.312-30.72-30.72-30.72H218.112v-67.584h587.776v67.584H685.056c-17.408 0-30.72 13.312-30.72 30.72v303.104c0 17.408 13.312 30.72 30.72 30.72h303.104c17.408 0 30.72-13.312 30.72-30.72V652.288c0-17.408-13.312-30.72-30.72-30.72z m-593.92-522.24h241.664v241.664H394.24V99.328z m-86.016 825.344H66.56V683.008h241.664v241.664z m649.216 0H715.776V683.008h241.664v241.664z"
          />
        </svg>
        <svg
          v-if="showCanvas"
          width="24"
          height="24"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#ffffff"
            d="M288 320h448a32 32 0 0 0 0-64H288a32 32 0 0 0 0 64zM288 544h448a32 32 0 0 0 0-64H288a32 32 0 0 0 0 64zM544 704H288a32 32 0 0 0 0 64h256a32 32 0 0 0 0-64zM896 132.928C896 77.28 851.552 32 796.928 32H227.04C172.448 32 128 77.28 128 132.928v758.144C128 946.72 172.448 992 227.04 992h435.008c1.568 0 2.912-0.672 4.416-0.896 8.96 1.6 18.464-0.256 25.984-6.528l192-160a31.424 31.424 0 0 0 10.816-27.2c0.16-1.184 0.736-2.208 0.736-3.424V132.928zM192 891.072V132.928C192 112.576 207.712 96 227.04 96h569.888C816.288 96 832 112.576 832 132.928V736h-96a96 96 0 0 0-96 96v96H227.04C207.712 928 192 911.424 192 891.072zM814.016 800L704 891.68V832a32 32 0 0 1 32-32h78.016z"
          />
        </svg>
      </div>
      <div class="icon" @click="handleIconClick(2)" v-if="true" :title="$t('operation.export')">
        <svg
          t="1727528836634"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="12697"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24"
          height="24"
        >
          <path
            fill="#ffffff"
            d="M894.6 532.3c-17.5 0-31.7 14.2-31.7 31.7v251c0 23.3-19 42.3-42.3 42.3H203.7c-23.3 0-42.3-19-42.3-42.3V233.7c0-23.3 19-42.3 42.3-42.3h270.7c17.5 0 31.7-14.2 31.7-31.7S492 128 474.4 128H203.7C145.4 128 98 175.4 98 233.7V815c0 58.3 47.4 105.7 105.7 105.7h616.9c58.3 0 105.7-47.4 105.7-105.7V564c0-17.5-14.2-31.7-31.7-31.7z"
            p-id="12698"
          ></path>
          <path
            fill="#ffffff"
            d="M253.1 688.9c98.5-93.2 197.9-237.3 373.7-228 12.4 0.7 22.1 10.4 22.1 22.9v61c-0.1 19.2 22.2 29.9 37.1 17.8l227.9-184.3c11.3-9.1 11.3-26.4-0.1-35.5L687 138.8c-14.9-12-38.1-1.4-38.2 17.7v61.8c0 11.6-7.9 20-19.3 22.6-302.5 69-379 423.6-376.4 448z"
            p-id="12699"
          ></path>
        </svg>
      </div>
      <div
        class="icon"
        @click="handleIconClick(3)"
        v-if="true"
        :title="isMerged ? $t('operation.split') : $t('operation.merge')"
      >
        <svg
          v-if="isMerged"
          width="24"
          height="24"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M448 128H128v192h64V192h192v288H256v-96l-160 128 160 128v-96h128v288H192v-128H128v192h320V128zM576 128h320v192h-64V192h-192v288h128v-96l160 128-160 128v-96h-128v288h192v-128h64v192H576V128z"
            fill="#ffffff"
            p-id="11576"
          ></path>
        </svg>
        <svg
          v-if="!isMerged"
          width="20"
          height="20"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#ffffff"
            d="M960 1024h-320a64 64 0 0 1-64-64v-256h64v256h320V64h-320v256H576V64s28.672-64 64-64h320a64 64 0 0 1 64 64v896s-28.672 64-64 64zM256 576H128V448h128V320l192 192-192 192V576z m128-512H64v896h320v-256h64v256s-28.672 64-64 64H64a64 64 0 0 1-64-64V64s28.672-64 64-64h320a64 64 0 0 1 64 64v256H384V64z m192 448l192-192v128h128v128h-128v128L576 512z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'ToolBar',
  props: {
    isMerged: {
      type: Boolean,
      required: true,
    },
    showCanvas: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggle-content', 'icon-click'],
  setup(props, { emit }) {
    const isHover = ref(false);
    const toggleContent = () => {
      emit('toggle-content');
    };

    const handleIconClick = (iconNumber) => {
      emit('icon-click', iconNumber);
    };

    return {
      isHover,
      toggleContent,
      handleIconClick,
    };
  },
};
</script>
<style scoped>
.toolbar {
  margin-right: 12px;
  padding: 5px 2px 5px 3px;
  border-radius: 7px;
  position: fixed;
  top: 100%;
  right: 0;
  transform: translateY(-112%);
  width: 30px;
  background-color: rgba(88, 88, 88, 0.3);
  transition: width 0.3s, height 0.3s, background-color 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.toolbar.expanded {
  width: 30px;
  background-color: rgba(0, 0, 0, 1);
}

.toolbar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 4px 0;
}

.menu-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: white;
  font-size: 14px;
}
</style>
