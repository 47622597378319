export const getLocalTimeInfo = (utcTimestamp: number) => {
  // 创建Date对象
  const date = new Date(utcTimestamp);

  // 获取当前日期
  const today = new Date();

  // 检查是否是今天
  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  // 格式化时间为24小时制
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const timeIn24 = `${hours}:${minutes}`;

  // 获取时区缩写
  const timeZonePart = new Intl.DateTimeFormat('en', { timeZoneName: 'short' })
    .formatToParts(date)
    .find((part) => part.type === 'timeZoneName');

  const timeZoneName = timeZonePart ? timeZonePart.value : 'Unknown';

  // 返回结果对象
  return {
    isToday,
    timeIn24,
    timeZoneName,
  };
};

export const getJSDateFromTicks = (ticks: number) => {
  const ticksTo1970 = 621355968000000000;

  // 将ticks转换为JavaScript时间戳(毫秒)
  const jsMilliseconds = (ticks - ticksTo1970) / 10000;

  //new date is ticks, converted to microtime, minus difference from epoch microtime
  return new Date(jsMilliseconds);
};
