<template>
  <div class="layout">
    <NavBar />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import NavBar from '@/components/NavBar.vue';

@Options({
  components: {
    NavBar,
  },
})
export default class LayoutView extends Vue {}
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 1024px; /* 设置最小宽度 */
}

.content {
  flex: 1;
  padding: 6px;
}

/* 确保在小于 1024px 宽度时，页面内容不会被压缩 */
@media (max-width: 1024px) {
  .layout {
    width: 1024px; /* 强制宽度为 1024px */
  }
}
</style>
