<template>
  <div>Class Diagram Document</div>
</template>

<script>
import { ref } from 'vue';

export default {
  components: {},
  setup() {
    const src = ref('');

    const changeHandle = (event) => {
      let file = event.target.files[0];
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = () => {
        src.value = fileReader.result;
      };
    };

    return {
      src,
      changeHandle,
    };
  },
};
</script>
