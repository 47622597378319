// src/services/apiService.ts
import { get, post, getBlob } from '@/api/request-lib';

export interface Data {
  id: number;
  name: string;
}

export const fetchData = async (): Promise<string> => {
  try {
    const data = await get<string>('/api/erdiagram/public');
    return data;
  } catch (error) {
    console.error('Failed to fetch data:', error);
    throw error;
  }
};

export const fetchProtectedData = async (accessToken: string): Promise<string> => {
  try {
    const data = await get<string>(
      '/api/erdiagram/protected',
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error('Failed to fetch data:', error);
    throw error;
  }
};

export const fetchLatestERDiagramData = async (accessToken: string, cultureInfo: string): Promise<object> => {
  try {
    const data = await get<object>(
      `/api/classdiagram?t=${Date.now()}`,
      {
        cultureInfo,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error('Failed to fetch data:', error);
    throw error;
  }
};

export const fetchUserInfo = async (accessToken: string): Promise<object> => {
  try {
    const data = await get<object>(
      `/api/classdiagram/profile?t=${Date.now()}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error('Failed to fetch data:', error);
    throw error;
  }
};

// 添加post方法，url是/api/classdiagram/chat, 使用 Authorization: `Bearer ${accessToken}`
export const chatWithClassDiagram = async (payload: any, accessToken: string): Promise<any> => {
  try {
    const data = await post<any>('/api/classdiagram/chat', payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (error) {
    console.error('Failed to chat with class diagram:', error);
    throw error;
  }
};

export const submitData = async (payload: Data): Promise<Data> => {
  try {
    const result = await post<Data>('/data', payload);
    return result;
  } catch (error) {
    console.error('Failed to submit data:', error);
    throw error;
  }
};

export const exportDocx = async (accessToken: string, cultureInfo: string, userName: string): Promise<object> => {
  try {
    const data = await getBlob(
      `/api/classdiagram/export?t=${Date.now()}`,
      {
        type: 'DOCX',
        cultureInfo,
        userName,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error('Failed to export data:', error);
    throw error;
  }
};
