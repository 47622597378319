import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7926524f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "input-length-info" }
const _hoisted_4 = {
  key: 0,
  class: "max-length-warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CanvasContainer = _resolveComponent("CanvasContainer")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CanvasContainer, { showCanvas: true }),
    _createElementVNode("div", {
      class: _normalizeClass(["chatBox", { dark: _ctx.theme === 'dark' }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_input, {
          ref: "textareaRef",
          type: "textarea",
          rows: 1,
          placeholder: _ctx.$t('chat.pleaseInput'),
          modelValue: _ctx.textarea,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textarea) = $event)),
          autosize: _ctx.autosize,
          onKeydown: _ctx.handleKeyDown,
          onInput: _ctx.handleInput,
          disabled: false,
          maxlength: _ctx.MAX_LENGTH,
          class: "transparent-input",
          "show-word-limit": ""
        }, null, 8, ["placeholder", "modelValue", "autosize", "onKeydown", "onInput", "maxlength"]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.isMaxLengthReached)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('chat.maxLengthReached')), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          class: "clean-button",
          onClick: _ctx.handleClean,
          disabled: _ctx.isSubmitButtonDisabled,
          icon: _ctx.DeleteFilled,
          circle: ""
        }, null, 8, ["onClick", "disabled", "icon"])
      ])
    ], 2)
  ]))
}