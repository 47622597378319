// src/api/request.ts
import axiosInstance from './axiosInstance';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

const request = async <T>(config: AxiosRequestConfig): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance(config);
    return response.data; // 直接返回数据部分
  } catch (error) {
    // 使用类型断言将 error 转换为 any 类型
    const axiosError = error as any;

    // 细化错误处理
    if (axiosError.response) {
      // 请求已发出，但服务器响应了状态码，状态码不是 2xx
      console.error('Server responded with an error:', axiosError.response.status);
      console.error('Response data:', axiosError.response.data);
    } else if (axiosError.request) {
      // 请求已发出，但没有收到响应
      console.error('No response received:', axiosError.request);
    } else {
      // 其他错误
      console.error('Error setting up the request:', axiosError.message);
    }
    console.error('Request config:', axiosError.config);
    throw axiosError;
  }
};

// 封装 GET 请求
export const get = <T>(url: string, params?: any, config?: AxiosRequestConfig): Promise<T> => {
  return request<T>({
    method: 'get',
    url,
    params,
    ...config,
  });
};

// 封装 POST 请求
export const post = <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
  return request<T>({
    method: 'post',
    url,
    data,
    ...config,
  });
};

// 封装 PUT 请求
export const put = <T>(url: string, data?: any): Promise<T> => {
  return request<T>({
    method: 'put',
    url,
    data,
  });
};

// 封装 DELETE 请求
export const del = <T>(url: string): Promise<T> => {
  return request<T>({
    method: 'delete',
    url,
  });
};

// 封装 GET 请求以获取 Blob
export const getBlob = async (url: string, params?: any, config?: AxiosRequestConfig): Promise<Blob> => {
  return request<Blob>({
    method: 'get',
    url,
    params,
    responseType: 'blob', // 指定响应类型为 blob
    ...config,
  });
};

export default request;
