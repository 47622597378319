import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6d609c43"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "markdown-content",
  ref: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showDocument)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512))
    : _createCommentVNode("", true)
}