<template>
  <div class="scroll-container" ref="containerRef" @wheel="handleWheel">
    <div
      class="content-wrapper"
      :class="{
        'show-all': showAll,
        'start-scroll': shouldStartScroll && !showAll,
        'show-back': showBack,
      }"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="content-item"
        :class="{
          'fade-in': index < fadeInCount && !showAll && !shouldStartScroll,
          visible: isItemVisible(index),
          'reset-scroll': resetScroll,
          'show-back': showBack,
        }"
        :style="{
          'animation-delay': index < fadeInCount && !showBack ? `${index * fadeInValue}s` : '0s',
        }"
      >
        <img class="img" :src="item.src" />
        <div class="content" :class="{ isDark: isDarkMode }">
          <div class="title">{{ $t('home[' + index + '].title') }}</div>
          <div class="bottom">
            <div>{{ $t('home[' + index + '].contents[0]') }}</div>
            <div>{{ $t('home[' + index + '].contents[1]') }}</div>
            <div>{{ $t('home[' + index + '].contents[2]') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useDark } from '@vueuse/core';
import img1 from '@/assets/item1a.png';
import img2 from '@/assets/item2a.png';
import img3 from '@/assets/item3a.png';
import img4 from '@/assets/item4a.png';
import img5 from '@/assets/item5a.png';
import img6 from '@/assets/item6a.png';

const containerRef = ref(null);
const showAll = ref(false);
const animationStarted = ref(false);
const shouldStartScroll = ref(false);
const resetScroll = ref(false);
const showBack = ref(false);
const fadeInCount = 3;
const fadeInValue = 3;
const isDarkMode = useDark();

let timeoutIds = [];

// 图片路径数组
const items = [{ src: img1 }, { src: img2 }, { src: img3 }, { src: img4 }, { src: img5 }, { src: img6 }];

// 计算每个item是否应该显示
const isItemVisible = (index) => {
  if (showAll.value || shouldStartScroll.value || resetScroll.value) return true;
  if (index < fadeInCount) return animationStarted.value;
  return false;
};

// 计算需要滚动的距离
const calculateScrollPosition = () => {
  if (!containerRef.value) return 0;
  const containerHeight = containerRef.value.clientHeight;
  // 计算到完整显示第6个div所需的滚动距离
  return containerHeight + 220; // 保持和transform动画一致的距离
};

// 处理滚轮事件
const handleWheel = () => {
  if (!showAll.value) {
    showAll.value = true;
    // 立即滚动到正确位置
    containerRef.value.scrollTop = calculateScrollPosition();
  }
};

// 组件挂载后开始动画
onMounted(() => {
  animationStarted.value = true;

  const firstTimeout = setTimeout(() => {
    // 前2个元素淡入完成后开始滚动动画
    shouldStartScroll.value = true;
    const secondTimeout = setTimeout(() => {
      // 滚动结束后开始淡出
      resetScroll.value = true;
      const thirdTimeout = setTimeout(() => {
        // 恢复顶部
        requestAnimationFrame(() => {
          containerRef.value.scrollTop = 0;
        });
        resetScroll.value = false;
        showAll.value = false;
        showBack.value = true;
      }, 1000);
      timeoutIds.push(thirdTimeout);
    }, 32000);
    timeoutIds.push(secondTimeout);
  }, fadeInValue * (fadeInCount - 1) * 1000);
  timeoutIds.push(firstTimeout);
});

// 组件卸载前清除所有定时器
onBeforeUnmount(() => {
  timeoutIds.forEach((id) => clearTimeout(id));
  timeoutIds = [];
});
</script>

<style scoped>
.scroll-container {
  height: calc(100vh - 72px);
  overflow: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.content-wrapper {
  position: relative;
  min-width: 640px;
  width: 1024px;
  margin: auto;
}

.content-item {
  justify-content: flex-start;
  display: flex;
  height: 33.33vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border-radius: 8px;
  opacity: 0;
}
.content-item:nth-child(odd) {
  flex-direction: row;
}
.content-item:nth-child(even) {
  flex-direction: row-reverse;
}
/* 前3个元素的淡入动画 */
.fade-in.visible {
  animation: fadeIn 2.5s forwards;
}

/* 整体滚动动画 */
.start-scroll {
  animation: scrollContent 30s forwards linear;
}

.start-scroll .content-item {
  opacity: 1;
}

.reset-scroll .content-item {
  opacity: 0;
}

/* 显示所有内容时的样式 */
.show-all {
  animation: none;
}

.show-all .content-item {
  opacity: 1;
}

/* 显示所有后启用滚动 */
.scroll-container:has(.show-all) {
  overflow-y: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scrollContent {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100vh - 150px));
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.reset-scroll {
  animation: fadeOut 3s forwards;
}

.show-back {
  animation: fadeIn 2s forwards;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.content.isDark {
  color: #ccc;
}
.title {
  font-size: 52px;
  margin-bottom: 20px;
}
.img {
  height: 100%;
}
.bottom {
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-size: 24px;
}
</style>
