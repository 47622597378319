<template>
  <el-header>
    <el-row type="flex" justify="space-between" align="middle">
      <el-col :span="1">
        <div class="logo">
          <img :src="logoPath" alt="Logo" />
        </div>
      </el-col>
      <el-col :span="14">
        <el-menu mode="horizontal" :default-active="activeIndex">
          <el-menu-item index="/" @click="navigateTo('/', '1')">{{ $t('menu.home') }}</el-menu-item>
          <el-sub-menu index="/demo">
            <template #title>{{ $t('menu.demo') }}</template>
            <el-menu-item index="/tools/class-diagram" @click="navigateTo('/tools/class-diagram', '2')">{{
              $t('menu.classDiagram')
            }}</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="/document">
            <template #title>{{ $t('menu.document') }}</template>
            <el-menu-item index="/document/class-diagram" @click="navigateTo('/document/class-diagram', '3')">{{
              $t('menu.classDiagram')
            }}</el-menu-item>
            <el-menu-item index="/document/bop" @click="handleBOP()">{{ $t('menu.bop') }}</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-col>
      <el-col :span="9" style="text-align: right; border-bottom: 1px solid var(--el-menu-border-color); padding: 8px 0">
        <!-- 使用 el-row 包裹两个子 div -->
        <el-row type="flex" justify="end" align="middle" style="height: 43px">
          <!-- Dark Mode 开关 -->
          <el-switch
            :class="{ isLight: !isDark }"
            v-model="isDark"
            inline-prompt
            :active-action-icon="SunIcon"
            :inactive-action-icon="MoonIcon"
            @change="useToggle(!isDark)"
          />
          <!-- 语言选择模块 -->
          <div class="language" v-if="true">
            <el-select
              v-model="currentLanguage"
              @change="handleLanguageChange"
              @visible-change="visableChange"
              placeholder="Language"
              style="width: 130px; padding-right: 10px"
            >
              <el-option v-for="lang in availableLanguages" :key="lang.value" :label="lang.label" :value="lang.value">
              </el-option>
            </el-select>
          </div>
          <div v-if="!isAuthenticated">
            <el-button type="primary" @click="login" plain>{{ $t('menu.login') }}</el-button>
          </div>
          <div v-else>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                <el-avatar :size="40" :src="userInfo.picture"></el-avatar>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="profile">{{ $t('menu.profile') }}</el-dropdown-item>
                  <el-dropdown-item command="logout">{{ $t('menu.logout') }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-row>
      </el-col>
    </el-row>
  </el-header>
  <el-dialog
    :before-close="handleClose"
    :show-close="false"
    v-model="showDialog"
    :title="$t('attention')"
    width="500"
    align-center
  >
    <div>{{ $t('emailAlert1') }}</div>
    <div>{{ $t('emailAlert2') }}</div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="handleClose">{{ $t('known') }} </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
import { ref, watchEffect, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import logoPath from '@/assets/site.svg';
import { onMounted } from 'vue';
import { useDark, useToggle } from '@vueuse/core';
import SunIcon from './icons/SunIcon.vue'; // 引入太阳图标组件
import MoonIcon from './icons/MoonIcon.vue'; // 引入月亮图标组件

export default {
  name: 'NavBar',
  components: {
    // eslint-disable-next-line
    SunIcon,
    // eslint-disable-next-line
    MoonIcon,
  },
  setup() {
    const { loginWithPopup, logout, getAccessTokenSilently, isAuthenticated, user } = useAuth0();
    const activeIndex = ref('/');
    const route = useRoute();
    const store = useStore();
    const userInfo = ref({});
    const showDialog = ref(false);

    // 使用 watchEffect 来监听 user 对象的变化
    watchEffect(() => {
      if (user.value) {
        userInfo.value = { ...user.value };
      }
    });

    watch(
      () => route.path,
      (newPath) => {
        // Update activeIndex based on current route
        if (newPath === '/') {
          activeIndex.value = '1';
        } else if (newPath.startsWith('/tools')) {
          activeIndex.value = '2';
        } else if (newPath.startsWith('/document')) {
          activeIndex.value = '3';
        }
        // Add more conditions as needed
      },
      { immediate: true } // This ensures it runs on component mount
    );

    const logoutAll = () => {
      // 清除 localStorage 或 sessionStorage
      localStorage.clear();
      sessionStorage.clear();

      // 清除 Vuex 状态
      store.dispatch('resetState');

      logout({ logoutParams: { returnTo: window.location.origin } });
    };
    const handleCommand = (command) => {
      if (command === 'logout') {
        activeIndex.value = '/';
        logoutAll();
      } else if (command === 'profile') {
        store.dispatch('navigateTo', '/profile');
      }
    };

    const handleClose = () => {
      logoutAll();
    };

    const navigateTo = (path, index) => {
      activeIndex.value = index;
      store.dispatch('navigateTo', path);
    };

    const login = async () => {
      try {
        await loginWithPopup();
        // 登录成功后，手动更新用户信息
        if (user.value) {
          userInfo.value = { ...user.value };
        }
        // 获取访问令牌并存储在缓存中
        const accessToken = await getAccessTokenSilently();
        store.dispatch('setAccessToken', accessToken);
        navigateTo('/');
      } catch (error) {
        localStorage.clear();
        sessionStorage.clear();
        store.dispatch('resetState');
        showDialog.value = true;
      }
    };

    // 语言选择相关逻辑
    const availableLanguages = ref([
      { label: 'English', value: 'en-US' }, // 使用 'en' 而不是 'en-US'
      { label: '简体中文', value: 'zh-CN' }, // 保持 'zh-CN'
      { label: '繁體中文', value: 'zh-TW' }, // 使用 'zh-TW' 而不是 'zh-Hant'
      //{ label: 'Español', value: 'es' }, // 使用 'es' 而不是 'es-ES'
      //{ label: '日本語', value: 'ja' }, // 使用 'ja' 而不是 'ja-JP'
      //{ label: 'Français', value: 'fr' }, // 使用 'fr' 而不是 'fr-FR'
      //{ label: 'Deutsch', value: 'de' }, // 使用 'de' 而不是 'de-DE'
      //{ label: 'Italiano', value: 'it' }, // 使用 'it' 而不是 'it-IT'
      //{ label: '한국어', value: 'ko' }, // 使用 'ko' 而不是 'ko-KR'
      //{ label: 'Русский', value: 'ru' }, // 使用 'ru' 而不是 'ru-RU'
      //{ label: 'Português', value: 'pt' }, // 使用 'pt-BR' 而不是 'pt-PT'
      //{ label: 'العربية', value: 'ar-SA' }, // 保持 'ar-SA'
      //{ label: 'हिन्दी', value: 'hi' }, // 使用 'hi' 而不是 'hi-IN'
    ]);

    // 从 store 中获取当前语言
    const currentLanguage = ref(store.getters.currentLanguage);
    const handleLanguageChange = (value) => {
      store.dispatch('setCurrentLanguage', value);
    };

    const handleBOP = () => {
      switch (store.getters.currentLanguage) {
        case 'zh-CN':
          window.open('https://github.com/BA2Ops/BOP/blob/master/zh-CN/README.md', '_blank');
          break;
        case 'zh-TW':
          window.open('https://github.com/BA2Ops/BOP/blob/master/zh-TW/README.md', '_blank');
          break;
        default:
          window.open('https://github.com/BA2Ops/BOP/blob/master/en/README.md', '_blank');
      }
    };

    const visableChange = (isViable) => {
      console.log(isViable);
      store.dispatch('setLanguageSelecting', isViable);
    };

    onMounted(() => {
      // 确保在组件挂载时设置正确的语言
      const storedLanguage = store.getters.currentLanguage;
      if (storedLanguage) {
        currentLanguage.value = storedLanguage;
      } else {
        // 如果没有存储的语言，则使用浏览器默认语言的前两个字符（如 'en'、'zh' 等）
        const browserLang = navigator.language.split('-')[0];
        const matchedLang = availableLanguages.value.find((lang) => lang.value.startsWith(browserLang));
        const defaultLang = matchedLang ? matchedLang.value : 'en-US';
        store.dispatch('setCurrentLanguage', defaultLang);
        currentLanguage.value = defaultLang;
      }
    });

    // Dark Mode 相关逻辑
    const isDark = useDark();
    return {
      login,
      useToggle,
      handleClose,
      isAuthenticated,
      userInfo,
      activeIndex,
      handleCommand,
      logoPath,
      navigateTo,
      availableLanguages,
      currentLanguage,
      handleLanguageChange,
      isDark,
      SunIcon,
      MoonIcon,
      showDialog,
      handleBOP,
      visableChange,
    };
  },
};
</script>

<style scoped>
.language {
  margin: 0 0 0 10px;
}
.el-header {
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.logo {
  cursor: pointer;
}
.logo img {
  height: 40px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.dark-mode {
  background-color: #121212;
  color: #ffffff;
}
</style>
