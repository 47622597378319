<template>
  <div v-if="showDocument" class="markdown-content" ref="container"></div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, nextTick, watch } from 'vue';
import { useStore } from 'vuex';
import { renderAsync } from 'docx-preview';

export default defineComponent({
  components: {},
  name: 'MarkdownContent',
  props: {
    showDocument: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const store = useStore();
    const container = ref<HTMLElement | null>(null);

    const base64ToBlob = (base64: string, contentType: string) => {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    };

    const renderDocx = async () => {
      try {
        const content = store.getters.getOpenXMLContent;
        const docxBlob = base64ToBlob(
          content,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );

        if (container.value) {
          const obj = container.value;
          await renderAsync(docxBlob, container.value).then(() => {
            // 渲染完成后，修改样式
            const wrapper = obj.querySelector('.docx-wrapper') as HTMLElement | null;
            if (wrapper) {
              wrapper.style.background = 'transparent';
            }

            const docxContent = obj.querySelector('.docx-wrapper > section.docx') as HTMLElement | null;
            if (docxContent) {
              //docxContent.style.width = '100%'; // 设置宽度为100%
              //docxContent.style.boxSizing = 'border-box'; // 确保内容不会超出容器
              docxContent.style.boxShadow = '10px 0 13px #999';
            }
          });
        } else {
          console.error('Container element not found');
        }
      } catch (error) {
        console.error('Error rendering DOCX:', error);
      }
    };

    onMounted(() => {
      //if (props.showDocument) {
      //  nextTick(() => {
      //    renderDocx();
      //  });
      //}
    });

    watch(
      () => props.showDocument,
      (newVal) => {
        if (newVal) {
          nextTick(() => {
            renderDocx();
          });
        }
      }
    );

    watch(
      () => [props.showDocument, store.getters.getOpenXMLContent],
      ([newShowDocument, newContent]) => {
        if (newShowDocument && newContent) {
          nextTick(() => {
            renderDocx();
          });
        }
      }
    );

    return {
      container,
    };
  },
});
</script>

<style scoped>
.markdown-content {
  flex: 1;
  height: 100%;
  padding: 20px;
  background-color: transparent;
  box-sizing: border-box;
  text-align: left;
}
</style>
