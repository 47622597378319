<template>
  <div>
    <h1>Callback Page</h1>
    <div v-if="user">
      <h2>User Profile</h2>
    </div>
    <div v-else>
      <p>Loading user information...</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

export default defineComponent({
  name: 'Auth0CallbackHandler',
  setup() {
    const { isAuthenticated, user } = useAuth0();

    return {
      isAuthenticated,
      user,
    };
  },
});
</script>
