import { i18n } from '@/i18n';

interface InstructionParamIndex {
  [key: string]: {
    [key: string]: number[];
  };
}

const instructionParamIndex: InstructionParamIndex = {
  '10100': {
    '300': [0, 1],
    '100': [0, 1],
    '1000': [0, 1],
  },
  '10200': {
    '300': [0],
    '700': [1],
    '100': [0],
    '500': [1],
  },
  '10300': {
    '300': [0],
    '100': [0],
  },
  '10400': {
    '300': [0],
    '200': [0],
    '400': [0],
  },
  '10500': {
    '300': [0],
    '700': [1],
    '400': [0, 1],
    '100': [0],
    '600': [1],
  },
  '10600': {
    '300': [0, 1],
    '400': [0, 1],
    '100': [0, 1],
    '1400': [0, 1],
    '1500': [2, 3],
  },
  '10700': {
    '300': [0, 3],
    '1700': [1],
    '400': [0, 3],
    '1500': [2],
  },
  '10800': {
    '300': [0, 2],
    '1700': [1],
    '400': [0, 2],
    '1600': [1],
    '100': [0, 2],
    '1400': [0, 2],
  },
  '10900': {
    '1300': [0],
    '300': [1, 2],
    '400': [1, 2],
    '100': [1, 2],
  },
  '11000': {
    '300': [0],
    '700': [1],
    '400': [0],
    '100': [0],
    '900': [2, 3],
  },
  '11100': {
    '300': [0],
    '700': [1],
    '400': [0],
    '100': [0],
    '200': [1],
    '600': [1],
  },
  '11200': {
    '300': [0, 1],
    '400': [1],
    '100': [0],
    '200': [1],
  },
  '11300': {
    '1800': [0],
  },
  '11400': {
    '0': [],
  },
  '11500': {
    '0': [],
  },
};

/*
函数处理逻辑
1. 使用useI18n的t方法从语言包中获得template
2. 按 instructionTypeId 从语言包中获得对应instructions的对应成员instructionName
3. 按 validationTypeId 从语言包中获得validateTypes对应的成员[ validationTypeName, paramType]
4. 从 instructionParamIndex 中按instructionTypeId和validationTypeId 获取参数的 index
5. 从 params 中按参数的 index，获取值并转换成逗号分割的字符串paramString
6. 按照template的占位符，对应传递的参数形成最终的提示信息字符串
4. 返回提示信息字符串

# 使用数据类型断言确保每个语句的数据类型匹配
*/
export const getInstrctionMessage = (instructionTypeId: string, validationTypeId: string, params: string[]) => {
  const { t } = i18n.global;

  let key = `instructions.${instructionTypeId}`;
  const instructionName = t(key);
  if (instructionName == key) {
    return t('commonError');
  }

  key = `validateTypes.${validationTypeId}.validationTypeName`;
  const validationTypeName = t(key);
  if (validationTypeName == key) {
    return t('commonError');
  }

  key = `validateTypes.${validationTypeId}.paramType`;
  const paramType = t(key);
  if (paramType == key) {
    return t('commonError');
  }

  const paramIndexes = instructionParamIndex[instructionTypeId]?.[validationTypeId] || [];
  const paramString = paramIndexes.map((index) => `\`${params[index]}\``).join(', ');
  if (paramString === '`undefined`') {
    return t('commonError');
  }

  return t('template', {
    instructionName,
    validationTypeName,
    paramType,
    params: paramString,
  });
};
