import { createI18n } from 'vue-i18n';
import { computed } from 'vue';
import enUS from './locales/en-US';
import zhCN from './locales/zh-CN';
import zhTW from './locales/zh-TW';
import store from './store'; // 导入 Vuex store

// 定义支持的语言类型
type SupportedLocales = 'zh-CN' | 'en-US' | 'zh-TW';
function matchLanguage(language: string): SupportedLocales {
  if (!['zh-CN', 'en-US', 'zh-TW'].includes(language)) {
    language = 'en-US';
  }
  store.dispatch('setCurrentLanguage', language);
  return language as SupportedLocales;
}

// 获取浏览器语言并匹配支持的语言
function getLanguage(): SupportedLocales {
  // 优先获取 Vuex 中的语言值
  let storedLanguage = store.getters.currentLanguage;
  if (!storedLanguage) {
    // 如果没有缓存值，获取浏览器语言
    storedLanguage = navigator.language;
  }
  return matchLanguage(storedLanguage);
}

// 定义语言包
const messages = {
  'en-US': enUS,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
};

// 创建 i18n 实例
const i18n = createI18n({
  legacy: false, // 如果你使用 Composition API，需要设置为 false
  locale: getLanguage(), // 默认语言
  fallbackLocale: 'en-US', // 回退语言
  messages,
});

// 创建一个计算属性来处理语言设置
const locale = computed<SupportedLocales>({
  get: () => i18n.global.locale.value as SupportedLocales,
  set: (value: SupportedLocales) => {
    store.dispatch('setCurrentLanguage', value); // 更新 Vuex 中的语言值
  },
});

export { i18n, locale };
