<script lang="ts">
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from 'vue';
import CanvasContainer from '@/components/CanvasContainer.vue';
import { ElDialog, ElIcon, ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { ChatSquare, DeleteFilled } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'ClassDiagramResults',
  components: {
    CanvasContainer,
  },
  setup(props, { emit }) {
    const textarea = ref('');
    const { t, locale } = useI18n();
    const autosize = { minRows: 1, maxRows: 5 };
    const isMaxLengthReached = ref(false);
    const isSubmitButtonDisabled = computed(() => textarea.value.trim() === '');
    const textareaRef = ref<HTMLTextAreaElement | null>(null);
    const MAX_LENGTH = 128;
    let focusInterval: number | null = null;

    const textareaLength = computed(() => textarea.value.length);
    const remainingChars = computed(() => MAX_LENGTH - textareaLength.value);
    const theme = computed(() => localStorage.getItem('vueuse-color-scheme') || 'light');

    const handleInput = () => {
      isMaxLengthReached.value = textarea.value.length >= MAX_LENGTH;
      if (window.opener && !window.opener.closed) {
        const result = window.opener.postMessage(textarea.value, window.location.origin);
        if (result == '') {
          textarea.value = '';
        }
      } else {
        alert('Parent window is closed or not accessible.');
      }
    };

    const handleKeyDown = (event: {
      key: string;
      altKey: any;
      ctrlKey: any;
      shiftKey: any;
      preventDefault: () => void;
    }) => {
      // 检查按下的键是否为 'Enter'，并且没有按下 Alt、Ctrl 或 Shift 键
      if (event.key === 'Enter' && !event.altKey && !event.ctrlKey && !event.shiftKey) {
        // 阻止默认的 'Enter' 键行为（通常是换行）
        event.preventDefault();

        // 检查输入框的内容是否为空（去掉前后空格后）
        if (textarea.value.trim() == '') {
          // 如果为空，显示警告信息
          ElMessage.warning(t('chat.emptyInfoNotAllow'));
        } else {
          // 如果不为空，调用提交函数
          handleInput();
        }
      }
      // 检查按下的键是否为 'Enter'，并且按下了 Ctrl 或 Alt 键
      else if (event.key === 'Enter' && (event.ctrlKey || event.altKey)) {
        // 阻止默认的 'Enter' 键行为
        event.preventDefault();

        // 在输入框中添加一个换行符
        textarea.value += '\n';
      }
      // 检查输入框的字符长度是否超过 128 个字符
      else if (textareaLength.value >= 128) {
        // 如果超过，截取前 127 个字符
        textarea.value = textarea.value.slice(0, 127);
      }
    };

    const focusTextarea = () => {
      if (textareaRef.value) {
        textareaRef.value.focus();
      }
    };

    const handleBeforeUnload = () => {
      window.opener?.postMessage('tabClosed', window.location.origin);
    };

    const handleClean = () => {
      textarea.value = '';
    };

    onMounted(() => {
      // Set up window close handler
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Set theme
      document.documentElement.classList.add(theme.value);

      // Set up focus interval
      focusInterval = window.setInterval(focusTextarea, 2000);

      // Initial focus
      focusTextarea();

      // Watch for theme changes
      const themeObserver = () => {
        const newTheme = localStorage.getItem('vueuse-color-scheme');
        document.documentElement.classList.remove('light', 'dark');
        document.documentElement.classList.add(newTheme || 'light');
      };
      window.addEventListener('storage', themeObserver);
      window.addEventListener('message', (event) => {
        if (event.origin !== window.location.origin) {
          return;
        }
        if (typeof event.data == 'string') {
          if (event.data != 'tabClosed') {
            textarea.value = event.data;
          }
        }
        return;
      });
    });

    onBeforeUnmount(() => {
      if (focusInterval) {
        clearInterval(focusInterval);
      }
      window.removeEventListener('beforeunload', handleBeforeUnload);
    });

    return {
      textarea,
      autosize,
      isMaxLengthReached,
      textareaRef,
      textareaLength,
      remainingChars,
      theme,
      handleKeyDown,
      handleInput,
      MAX_LENGTH,
      isSubmitButtonDisabled,
      handleClean,
      DeleteFilled,
    };
  },
});
</script>

<template>
  <div class="container">
    <CanvasContainer :showCanvas="true" />
    <div class="chatBox" :class="{ dark: theme === 'dark' }">
      <div class="text">
        <el-input
          ref="textareaRef"
          type="textarea"
          :rows="1"
          :placeholder="$t('chat.pleaseInput')"
          v-model="textarea"
          :autosize="autosize"
          @keydown="handleKeyDown"
          @input="handleInput"
          :disabled="false"
          :maxlength="MAX_LENGTH"
          class="transparent-input"
          show-word-limit
        />
        <div class="input-length-info">
          <span v-if="isMaxLengthReached" class="max-length-warning">
            {{ $t('chat.maxLengthReached') }}
          </span>
        </div>
      </div>
      <div>
        <el-button
          class="clean-button"
          @click="handleClean"
          :disabled="isSubmitButtonDisabled"
          :icon="DeleteFilled"
          circle
        ></el-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  height: 100vh;
  position: relative;
}

.chatBox {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 10px 3px;
  border-radius: 15px;
  border: solid 1px #444;
  margin: 10px;
  width: 500px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  display: flex;
}

.clean-button {
  margin-left: 10px;
}
.text {
  flex-grow: 1;
}
.length-counter {
  color: #ccc;
  float: right;
}

.input-length-info {
  margin-top: 5px;
  font-size: 10px;
}

.input-length-info span {
  margin-right: 10px;
}

.input-length-info span:last-child {
  margin-right: 0;
}

.max-length-warning {
  color: #ff4d4f;
}

.transparent-input :deep(.el-textarea__inner) {
  border-color: rgba(255, 255, 255, 0.2);
}

.dark .transparent-input :deep(.el-textarea__inner) {
  border-color: rgba(0, 0, 0, 0.2);
}
</style>
